import React from 'react';
import style from './resume.module.css'; // Import custom CSS file for section styles

const ResumeSection = () => {
    return (
        <section id="resume" className={`${style.resume_section}`}>
            <h2 className={`${style.section_title}`}>Resume & projects</h2>
            <div className={`${style.resume_container}`}>
                <a href="https://dezeepfabriek.roosmathias.be/" className={`${style.resume_card}`}>
                    <img src="./img/proj1.png" alt="Project 1" />
                    <h2 className={`${style.resume_card_title}`}>De Zeepfabriek</h2>
                </a> 
                <a href="https://degroenezebra.be/" className={`${style.resume_card}`}>
                    <img src="./img/proj2.png" alt="Project 2" />
                    <h2 className={`${style.resume_card_title}`}>De Groene Zebra</h2>
                </a>
                <a href="https://www.figma.com/file/qJebYF0PkkJoWzmDG3UTxA/Groenezebra---Design?type=design&node-id=0%3A1&mode=design&t=8gYCkemGSmskNK5U-1" className={`${style.resume_card}`}>
                    <img src="./img/proj3.png" alt="Project 3" />
                    <h2 className={`${style.resume_card_title}`}>Groene Zebra: Design</h2>
                </a>
                <a href="https://cm.roosmathias.be/" className={`${style.resume_card}`}>
                    <img src="./img/proj4.png" alt="Project 4" />
                    <h2 className={`${style.resume_card_title}`}>Airmusic</h2>
                </a>
            </div>
            <a href='./files/cv.pdf' className={`${style.resume_button}`}>Grab my CV</a>
            
        </section>
    );
};

export default ResumeSection;
