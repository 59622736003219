import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './Navigation';
import AboutMeSection from './pages/About';
import ResumeSection from './pages/Resume';
import InfoSection from './pages/Info';
import Footer from './components/Footer';




const App = () => {

  return (
  <>
    <Navigation />
    <InfoSection id="info" />
    <AboutMeSection id="about-me" />
    <ResumeSection id="resume" />
    <Footer/>
  </>
  );
};

export default App;