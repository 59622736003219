import React from 'react';
import { Container } from 'react-bootstrap';
import { FaLinkedin, FaFacebook, FaInstagram } from 'react-icons/fa';
import style from './footer.module.css'; // Import custom CSS file for footer styles

const Footer = () => {
  return (
    <footer className={`${style.footer} mt-auto bg-dark text-light`}>
      <Container className={`text-center`}>
        <p className={`mb-0`}>Mathias Roos - 2024</p>
        <div className={`${style.socials}`}>
          <a href="https://www.linkedin.com/in/mathias-roos-59459a256/" className={`${style.social_icon}`}>
            <FaLinkedin size="3.2rem" />
          </a>
          <a href="https://www.facebook.com/mathias.roos.14" className={`${style.social_icon}`}>
            <FaFacebook size="3.2rem" />
          </a>
          <a href="https://www.instagram.com/mathias.roos/" className={`${style.social_icon}`}>
            <FaInstagram size="3.2rem" />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
