import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Navigation.css'; // Import custom CSS file for navigation styles

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Navbar
      variant="dark"
      expand="lg"
      expanded={expanded}
      className={scrolling ? 'small-navbar' : 'navbar-custom'}
    >
      <Container>
        <Navbar.Brand href="#home">MATHIAS<span className='letter'>R</span></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link className='c-nav-link .active' href="#info">Info</Nav.Link>
            <Nav.Link className='c-nav-link' href="#about-me">About Me</Nav.Link>
            <Nav.Link className='c-nav-link' href="#resume">Resume</Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            <a href='mailto: mathiasroos12@gmail.com' className='button-contact'>Contact Me</a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;