import React from 'react';
import style from './info.module.css'; // Import custom CSS file for section styles

const InfoSection = () => {
    return (
        <header id="info" className={`${style.info_section}`}>
            <h1 className={`${style.info_title} ${style.anim_typewriter}`}>Hello I'm Mathias</h1>
            <h2 className={`${style.info_subtitle}`}>Webdeveloper & Designer</h2>
        </header>
    );
};

export default InfoSection;
