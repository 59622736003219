import React from 'react';
import style from './about.module.css'; // Import custom CSS file for section styles


const AboutMeSection = () => {
  return (
    <section id="about-me" className={`${style.about_me_section}`}>
      <div className={`${style.about_me_container}`}>
        <div className={`${style.left_side}`}>
          <div className={`${style.glassmorphism_container}`}>
            <img src=".\img\me.jpg" alt="Me" className={`${style.profile_picture}`} />
            <div className={`${style.personal_info}`}>
              <h2>Mathias Roos</h2>
              <p>Email: mathiasroos12@gmail.com</p>
              <p>Birthdate: April 27, 2000</p>
            </div>
          </div>
        </div>
        <div className={`${style.right_side}`}>
          <div className={`${style.glassmorphism_container}`}>
            <h2 className={`${style.section_title}`}>About me</h2>
            <p className={`${style.about_text}`}>
              Ever since I was just a little boy, I've been facinated with computers.
              I started with just playing games. But soon after I wanted to learn more about how they worked.
              In my last 2 years of high school started learning <span className='letter'>webdevelopment</span>. At first I learned the basics like html, css, javascript and php.
              After I graduated I knew that I wanted to continue in this sector, so I went to HOWEST to further my capabilities. 
              Here I became a better <span className='letter'>developer</span> & <span className='letter'>designer</span> and learned a lot of new things! 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeSection;
